<template>
  <div>
    <v-card class="mt-2">
      <v-card-title>
        <span>Utilidades do DEV</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <!-- CARD DO BANCO -->
        <v-card>
          <v-card-title> Banco de dados </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-textarea
                  @keydown.up="lastQuery"
                  @keydown.down="firstQuery"
                  v-model.trim="query"
                  rows="1"
                  label="Digite a query"
                  hint="Caixa de query"
                  @keydown.enter="runQuery"
                  clearable
                ></v-textarea>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  color="primary"
                  @click="runQuery"
                  @contextmenu="clearQuery"
                  >Executar</v-btn
                >
              </v-col>
            </v-row>
            <!-- TABELA -->
            <v-simple-table dark>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      v-for="(item, index) in queryHeaders"
                      :key="index"
                      class="text-left"
                    >
                      {{ item }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in queryRet" :key="index">
                    <td
                      v-for="Sitem in queryHeaders"
                      :key="Sitem"
                      class="text-left"
                    >
                      {{ item[Sitem] }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
        <!-- CARD DO COMANDO SHELL -->
        <v-card>
          <v-card-title> COMANDO SHELL </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-textarea
                  @keydown.up="lastCommand"
                  @keydown.down="firstCommand"
                  v-model.trim="command"
                  rows="1"
                  label="Digite o comando"
                  hint="Caixa do comando"
                  @keydown.enter="runShell"
                  clearable
                ></v-textarea>
              </v-col>
              <v-col cols="auto">
                <v-btn color="primary" @click="runShell" @contextmenu="clearCMD"
                  >Executar</v-btn
                >
              </v-col>
            </v-row>
            <!-- Retorno -->
            <v-textarea
              v-show="commandRet.length"
              v-model="commandRet"
              rows="5"
              label="Retorno do Comando"
              hint="Caixa do comando"
              readonly
              auto-grow
              outlined
            ></v-textarea>
            <!-- <code >{{commandRet}}</code> -->
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "TensorFlow",
  components: {},
  data: () => {
    return {
      query: "",
      queryRet: [],
      queryHeaders: [],
      queryHistory: [],
      queryPos: undefined,
      command: "",
      commandRet: "",
      commandHistory: [],
      commandPos: undefined,
      urlTensor: "",
      cameras: [],
      status: {},
      img: "",
      dialogImg: false,
    };
  },
  methods: {
    runQuery() {
      this.query = this.query.replace("\n", "");
      this.queryPos = undefined;
      this.queryRet = [];
      this.queryHeaders = [];
      this.$http
        .post("dev/db", { query: this.query })
        .then((response) => {
          this.queryRet = response.data;
          this.queryHeaders = Object.keys(response.data[0]);
          // this.queryHistory.push(this.query);
          this.addQueryHistory(this.query);
        })
        .catch(() => {});
    },
    runShell() {
      this.commandRet = "";
      this.commandPos = undefined;
      this.command = this.command.replace("\n", "");
      this.$http
        .post("dev/command", { command: this.command })
        .then((response) => {
          this.commandRet = response.data;
          this.addCommandHistory(this.command);
        })
        .catch(() => {});
    },
    addCommandHistory() {
      this.commandHistory.findIndex((item) => item == this.command) == -1
        ? this.commandHistory.push(this.command)
        : null;
      localStorage.setItem(
        "commandHistory",
        JSON.stringify(this.commandHistory)
      );
    },
    addQueryHistory() {
      this.queryHistory.findIndex((item) => item == this.query) == -1
        ? this.queryHistory.push(this.query)
        : null;
      localStorage.setItem("queryHistory", JSON.stringify(this.queryHistory));
    },
    lastCommand() {
      if (!this.commandPos) {
        this.commandPos = this.commandHistory.length - 1;
      } else {
        this.commandPos--;
      }
      this.command = this.commandHistory[this.commandPos];
    },
    firstCommand() {
      if (!this.commandPos) {
        this.commandPos = 0;
      } else {
        this.commandPos++;
      }
      this.command = this.commandHistory[this.commandPos];
    },
    lastQuery() {
      if (!this.queryPos) {
        this.queryPos = this.queryHistory.length - 1;
      } else {
        this.queryPos--;
      }
      this.query = this.queryHistory[this.queryPos];
    },
    firstQuery() {
      if (!this.queryPos) {
        this.queryPos = 0;
      } else {
        this.queryPos++;
      }
      this.query = this.queryHistory[this.queryPos];
    },
    clearQuery(e) {
      e.preventDefault();
      this.query = "";
      this.queryRet = [];
      this.queryHeaders = [];
    },
    clearCMD(e) {
      e.preventDefault();
      this.command = "";
      this.commandRet = [];
    },
  },
  mounted() {
    this.queryHistory = JSON.parse(
      localStorage.getItem("queryHistory") || "[]"
    );
    this.commandHistory = JSON.parse(
      localStorage.getItem("commandHistory") || "[]"
    );
  },
};
</script>

<style>
</style>